import React, { Component } from 'react';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';

import { Container, Col } from 'components/Layout/Grid';

import strings from 'helper/localization/localization';

import SEO from '../../components/Seo';

const SignoutContainer = styled(Container)`
  justify-content: center;
`;

const SignOutCol = styled(Col)`
  padding: 4rem 0;
`;

export default class SignOut extends Component {
  componentDidMount() {
    // https://github.com/gatsbyjs/gatsby/issues/309
    // navigate refers to `window` which isn't available in SSR
    // putting this in `componentDidMount` fixes it
    import('gatsby').then(({ navigate }) => {
      setTimeout(() => {
        Auth.signOut({ global: true }).then(() => {
          navigate('/');
        });
      }, 400);
    });
  }

  render() {
    return (
      <SignoutContainer>
        <SEO title="Sign Out" />
        <SignOutCol>
          <h1>{strings.signedOut}</h1>
          <p>
            {strings.returningHome}
          </p>
        </SignOutCol>
      </SignoutContainer>
    );
  }
}
